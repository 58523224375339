import React, {Component} from "react";
import BottomNav from './BottomNav';
import './Footer.css';


export default class Footer extends Component {
	render() {
		return (
			<div className='footer'>

				{'mktg admin'.includes(this.props.role) ? <BottomNav /> : <div />}

				<div className="footer-inner">
					<ul>
						<li>
							<small className="copyright">&copy; 2018 MKTG</small>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}