import React, {Component} from 'react';
import CheckboxFilters from '../../../components/drawers/CheckboxFilters/CheckboxFilters';

import './Filters.css';


export default class Filters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roles: null,
			rolesText: null,
		};
	}

	componentDidMount() {
		this.getFilters();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.refresh !== this.props.refresh) {
			this.getFilters()
		}
	}

	getFilters = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		fetch(process.env.REACT_APP_SERVER_API_URL + 'role',{
			method: 'GET',
			headers: {
				'Authorization': accessToken,
			}
		}).then((response) => {
			if(response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			if (responseJson.roles) {
				responseJson.roles.unshift({_id: "null", prettyName: "No Role"})
			}
			this.setState({
				roles: responseJson.data || []
			}, () => {
				this.updateButton()
			});
		}).catch( (err) => {
			console.log("err ====> ", err);
		});
	}

	static _mapArrayToIdPrettyNameObject(array) {
		if (!array) return {};
		return array.reduce((result, element) => {
			result[element._id] = element.prettyName;
			return result
		}, {})
	}

	updateButton = () => {
		let filters = localStorage.getItem("filters");
		let roles;
		if (filters) {
			filters = JSON.parse(filters);
			let rolesMap = Filters._mapArrayToIdPrettyNameObject(this.state.roles);

			if (filters.roles && filters.roles.length > 0) {
				roles = filters.roles
					.filter(rolesEl => rolesMap[rolesEl])
					.map(rolesEl => rolesMap[rolesEl])
					.join(", ")
			}
		}
		this.setState({
			rolesText: roles
		})
	}

	render() {
		return (
			<ul className={"filters no-bg"}>
				<li onClick={() => this.props.expandDrawer(
					<CheckboxFilters
						data={this.state.roles}
						label={"Role"}
						labelPlural="s"
						target={"roles"}
						alphabetize={false}
						updateButton={this.updateButton}
						handleRefresh={this.props.handleRefresh}
						handleTotalsRefresh={this.props.handleTotalsRefresh}
						getFilters={this.getFilters}
						handleCancel={this.props.handleCancel}
						narrow={true}
					/>)}
				>
					<a className={this.state.rolesText ? "is-active" : ""}>
						{this.state.rolesText || "No Role Selected"}
					</a>
					<span className="filter-ellipsis" />
				</li>
			</ul>
		)
	}

}
