import React, {Component} from 'react';
import tableau from 'tableau-api';
import * as qs from "querystring";


export default class DashboardViewer extends Component {
	componentDidMount() {
		let report_url = this.props.report.link;
		let params = this.props.params;
		this.initViz(report_url, params)
	}

	componentDidUpdate() {
		this.viz.dispose();
		let report_url = this.props.report.link;
		let params = this.props.params;
		this.initViz(report_url, params)
	}

	initViz = (report_url, params) => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		fetch(process.env.REACT_APP_SERVER_API_URL + "tableau",{
			method: 'GET',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => response.json()
		).then((responseJson) => {
			let server = process.env.REACT_APP_TABLEAU_SERVER;
			let site = process.env.REACT_APP_TABLEAU_TARGET_SITE;
			let vizUrl = '';
			if(params && Object.keys(params).length===0)
			{
				vizUrl = server + responseJson['data'] + '/t/' + site + '/views/' + report_url;
			} else{
				vizUrl = server + responseJson['data'] + '/t/' + site + '/views/' + report_url+'?'+qs.stringify(params);
			}
			const vizContainer = this.vizContainer;
			this.viz = new window.tableau.Viz(vizContainer, vizUrl);
		}).catch((err) => {
			console.log("err ====> ", err);
		});
	};

	render() {
		return (
			<div align="center" ref={(div) => {
				this.vizContainer = div
			}} />
		)
	}
}
