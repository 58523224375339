import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import './Main.css';


class Main extends Component {
	handleCollapse = () => {
		const drawerExpanded = document.body.classList.contains('drawer-expanded') || document.body.classList.contains('drawer-expanded-narrow');
		if (drawerExpanded) {
			this.props.onCollapse();
			this.props.history.push(this.props.location.pathname); // clear any active url params
		}
	}

	static scrollTop() {
		window.scrollTo(0, 0);
	}

	render() {
		const year = new Date().getFullYear();
		return (
			<section className="main" onClick={this.handleCollapse}>

				<div className="main-body">
					{this.props.children}
				</div>

				<footer className="main-footer">
					<hr className="stroke"/>
					<small className="copyright">&copy; {year ? year : "2018"} MKTG</small>
					<button onClick={Main.scrollTop} className="scroll-top"/>
				</footer>

			</section>
		)
	}
}

export default withRouter(Main);
