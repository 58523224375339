import React from 'react';


export default class PermissionAwarePage extends React.Component {
	render() {
		if (this.props.allowedPermission.includes(this.props.userPermission)) {
			return this.props.children;
		} else {
			return <div/>;
		}
	}
}
