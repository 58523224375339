import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {adalGetToken} from "react-adal";
import ReactGA from 'react-ga';
import {authContext} from "../adalConfig";
import CryptoJS from 'crypto-js';

import Launchpad from './Launchpad.js';
import Report from './Report/Report.js';
import Photo from './PhotoGallery/PhotoGallery'
import Loader from "./Shared/Loader/Loader"
import UserPermission from "./UserPermission/UserPermission";
import NotAllowed from "./Shared/Views/NotAllowed";


export default class App extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loginError: false
		}
	}

	componentWillMount() {
		// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
		ReactGA.pageview(window.location.pathname + window.location.search);

		if (localStorage.getItem('adal.idtoken') == null || !localStorage.getItem('user') || !localStorage.getItem('accessToken')) {
			adalGetToken(authContext, process.env.REACT_APP_API_CLIENT_ID, () => null)
				.then( async (token) => {
						await this.userLogin(token);
					}
				);
		} else {
			this.setState({'user': localStorage.getItem('user')});
		}
	}

	 userLogin = (IdToken) => {
		fetch(process.env.REACT_APP_SERVER_API_URL + "login",{
			method: 'GET',
			headers: {
				Authorization: IdToken
			}
		}).then( (response) => {
			if(response.ok) {
				return response.json()
			}
			else {
				localStorage.removeItem('accessToken');
				localStorage.removeItem('user');
				throw Error("User Unauthorized")
			}
		}).then( (responseJson) => {
			let user = {
				name: `${responseJson.data.user.firstName} ${responseJson.data.user.lastName}`,
				email: responseJson['data']['user']['email'],
				role: responseJson['data']['role']['name'],
				roleId: responseJson['data']['user']['roleId'],
				permission: responseJson['data']['user']['permission']
			}

			localStorage.setItem('accessToken', responseJson['data']['token']);
			localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), process.env.REACT_APP_SECERT_KEY));
			this.setState({
				'user': localStorage.getItem('user')
			});

		}).catch( (err) => {
			console.log("err ====> ", err);
			this.setState({
				loginError: true
			});
		});
	}

	render() {
		return (
			(this.state && this.state.user) ?
				<Switch>
					<Route path='/' exact={true} component={Launchpad}/>
					<Route path='/Report/:category?/:report?' exact={true} component={Report}/>
					<Route path='/Photo' exact={true} component={Photo}/>
					<Route path='/UserPermission' exact={true} component={UserPermission}/>
					<Redirect from='/null' exact={true} to='/'/>
					<Redirect from='/Null' exact={true} to='/'/>
				</Switch>
				:
				this.state.loginError ? <NotAllowed /> : <Loader/>
		)
	}
}