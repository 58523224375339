import moment from "moment";

export const getFilterParams = () => {
   const filters = JSON.parse(sessionStorage.getItem('filters'));
   let urlParams = '';

   if (filters) {
      for (const filter in filters) {
         if (filter) {
            const obj = filters[filter];

            if (obj.value) {
               // Change the datetime to the correct format.
               if(obj.id.includes("Date")){
                  urlParams += (obj.id + '=' + moment.utc(obj.value).format("YYYY-MM-DD HH:mm:ss[Z]") + '&');
               }
               else if(obj.id === "verified"){
                  if(obj.value !== "both"){
                     urlParams += (obj.id + '=' + obj.value + '&');
                  }
               }
               else{
                  if(obj.value.length > 0) {
                     urlParams += (obj.id + '=');
                     obj.value.forEach( item => {
                        urlParams += item.value + ',';
                     });
                    urlParams = urlParams.slice(0,-1) + '&';
                  }
               }
            }
         }
      }
      return urlParams.slice(0, -1);
   } else {
      return '';
   }
}

export const chunks = (arr, size) => {
   if (!Array.isArray(arr)) {
      throw new TypeError('Input should be Array');
   }

   if (typeof size !== 'number') {
      throw new TypeError('Size should be a Number');
   }

   var result = [];
   for (var i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, size + i));
   }

   return result;
}

