import React, {Component} from 'react';
import {Switch, withRouter} from 'react-router-dom';
import CryptoJS from "crypto-js";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga';

import RoleAwarePage from './components/elements/RoleAwarePage/RoleAwarePage';
import Sidebar from './components/structure/Sidebar/Sidebar';
import Permission from './components/sidebars/Permission/Permission'
import Drawer from './components/structure/Drawer/Drawer';
import Main from './components/structure/Main/Main';
import Users from './components/mains/Users/Users';
import Header from '../Shared/Header/Header';
import DataCorrupted from "../Shared/Views/DataCorrupted";


import './UserPermission.css';


class UserPermission extends Component {
	constructor(props) {
		super(props);

		try {
			let encryptedUser = localStorage.getItem('user');
			let decryptBytes = CryptoJS.AES.decrypt(encryptedUser.toString(), process.env.REACT_APP_SECERT_KEY);
			let user = (JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8)))

			this.state = {
				activeDrawer: null,
				activeModal: null,
				refresh: true,
				totalsRefresh: true,
				user: user,
				roles: [],

				dataError: false
			};
		} catch (err) {
			console.log("err ====> ", err);
			this.state = {
				dataError: true
			}
		}
	}

	expandDrawer = (drawerContent) => {
		this.setState({activeDrawer: drawerContent});
	}

	collapseDrawer = () => {
		this.setState({activeDrawer: null});
	}

	openModal = (modalContent) => {
		this.setState({activeModal: modalContent});
	}

	closeModal = () => {
		this.setState({activeModal: null});
	}

	handleCancel = (e) => {
		if (e) e.preventDefault();

		this.setState({
			activeDrawer: null
		});

		this.props.history.push(this.props.location.pathname); // clear any active url params
	}

	componentDidMount() {
		this.getRoles();
	}

	getRoles = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken || this.state.dataError) return;

		fetch(process.env.REACT_APP_SERVER_API_URL + 'role', {
			method: 'GET',
			headers: {
				'Authorization': accessToken,
			}
		}).then((response) => {
			if(response.ok) {
				return response.json()
			}
			else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			this.setState({
				roles: responseJson.data || []
			});
		}).catch( (err) => {
			console.log("err ====> ", err);
		});
	}

	handleRefresh = () => {
		this.setState({refresh: !this.state.refresh})
	}

	handleTotalsRefresh = () => {
		this.setState({totalsRefresh: !this.state.totalsRefresh})
	}

	logPageView = () => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	componentDidUpdate() {
		let narrow = false;

		if (this.state.activeDrawer) narrow = this.state.activeDrawer.props.narrow;

		if (this.state.activeDrawer && narrow) {
			document.body.classList.remove('drawer-expanded');
			document.body.classList.add('drawer-expanded-narrow');
			document.body.style.overflow = "hidden";
		} else if (this.state.activeDrawer) {
			document.body.classList.remove('drawer-expanded-narrow');
			document.body.classList.add('drawer-expanded');
			document.body.style.overflow = "hidden";
		} else {
			document.body.classList.remove('drawer-expanded', 'drawer-expanded-narrow');
			document.body.style.overflow = "auto";
		}
	}

	render() {
		return (
			this.state.dataError ? <DataCorrupted /> :

			<RoleAwarePage role={this.state.user.role}>
				<div className="header">
					<Header user={this.state.user} />
				</div>
				<div>
					<ToastContainer
						autoClose={2000}
					/>

					<Sidebar expandDrawer={this.expandDrawer} collapseDrawer={this.collapseDrawer}
							 handleRefresh={this.handleRefresh} handleTotalsRefresh={this.handleTotalsRefresh}>
						<Permission
							openModal={this.openModal}
							closeModal={this.closeModal}
							expandDrawer={this.expandDrawer}
							collapseDrawer={this.collapseDrawer}
							handleCancel={this.handleCancel}
							handleRefresh={this.handleRefresh}
							handleTotalsRefresh={this.handleTotalsRefresh}
							refresh={this.state.refresh}
							totalsRefresh={this.state.totalsRefresh}
							roles={this.state.roles}
						/>
					</Sidebar>

					<div className="wrapper">

						<Drawer>
							{this.state.activeDrawer}
						</Drawer>

						<Main onCollapse={this.collapseDrawer} handleCancel={this.handleCancel}
							  expandDrawer={this.expandDrawer} handleRefresh={this.handleRefresh}>
							<Switch onUpdate={this.logPageView}>
								<Users refresh={this.state.refresh} handleRefresh={this.handleRefresh}
									   expandDrawer={this.expandDrawer} collapseDrawer={this.collapseDrawer}
									   openModal={this.openModal} closeModal={this.closeModal} roles={this.state.roles}>
								</Users>
							</Switch>
						</Main>

					</div>

					<ReactModal
						isOpen={this.state.activeModal!==null}
						className="react-modal-content"
						overlayClassName="react-modal-overlay"
						shouldCloseOnOverlayClick={true}
						onRequestClose={this.closeModal}>
						{this.state.activeModal}
					</ReactModal>
				</div>
			</RoleAwarePage>
		);
	}
}

export default withRouter(UserPermission);;
