import React, {Component} from "react";
import {toast} from "react-toastify";

import {authContext} from "../../../../../adalConfig";
import Pagination from "../../../../Shared/Pagination/Pagination";
import SearchBar from "../../../../Shared/SearchBar/SearchBar";
import UserItem from "../../../components/mains/UserItem/UserItem";

import "./Users.css";


export default class Users extends Component {
	constructor(props) {
		super(props);

		this.state = {
			users: [],
			page: 1,
			limit: 25,
			total: 0,
			pages: 0,
			itemDisplayed: 0,
			roles: this.props.roles,
			search: "",
			label: "Users",
			activeSort: "lastName",
			isLoading: false
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.refresh !== this.props.refresh) {
			this.setState({page: 1}, () => {
				this.getUsers();
			})
		}
	}

	componentDidMount() {
		this.getUsers();
	}

	buildQueryString = () =>{
		let qs = "";
		let filters = localStorage.getItem("filters");
		if (filters) {
			filters = JSON.parse(filters);
			qs +=
				filters.roles && filters.roles.length > 0
					? `&roleId=${filters.roles.join("%20")}`
					: "";
		}
		if (this.state.search) {
			qs += "&search=";
			qs += this.state.search;
		}
		if (this.state.activeSort) {
			qs += "&sort=";
			qs += this.state.activeSort;
		}

		return qs;
	}

	getUsers = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;
		this.setState({isLoading: true});

		fetch(process.env.REACT_APP_SERVER_API_URL + `user?page=${this.state.page}` +
			`&limit=${this.state.limit}&searchProperties=lastName,firstName${this.buildQueryString()}`,{
				method: "GET",
				headers: {
					"Authorization": accessToken
				}
			}
		).then(response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.getUsers();
				});
			} else if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then(responseJson => {
			this.setState({
				users: responseJson.data.users,
				total: responseJson.data.total,
				pages: responseJson.data.pages,
				page: responseJson.data.page,
				itemDisplayed: responseJson.data.users.length,
				isLoading: false
			});
		}).catch(err => {
			console.log("Error: " + err)
			toast("Error Loading users", {className: "notification error"});
			this.setState({isLoading: false});
		});
	}

	onPageUpdate = (data) => {
		if (isNaN(data.page)) return;
		if (+data.page > this.state.pages) {
			data.page = this.state.pages;
		}
		if (+data.page < 1) {
			data.page = 1;
		}
		this.setState({page: data.page}, () => {
			this.getUsers();
		});
	}

	handleSearch = (value) => {
		this.setState({search: value, page: 1}, () => {
			this.getUsers();
		});
	}

	renderUserList = () =>{
		if (this.state.isLoading && this.state.users.length === 0) {
			return null;
		} else if (this.state.users.length) {
			return (
				<div className="users-table">
					{this.state.users.map((user, i) => (
						<UserItem
							selectedUser={user}
							key={i}
							roles={this.state.roles}
							getUsers={this.getUsers}
							{...this.props}
						/>
					))}
				</div>
			);
		} else {
			return (
				<div className="users-table empty">
					<p>No results found.</p>
				</div>
			)
		}
	}

	render() {
		return (
			<div className="users">
				{/*<div className="users-header">*/}
					<SearchBar
						search={this.state.search}
						onChange={this.handleChange}
						onSearch={this.handleSearch}
						placeholder="Search Users"
					/>
					<Pagination {...this.state} onUpdate={this.onPageUpdate} />

				{/*</div>*/}
				{this.renderUserList()}
				{!this.state.isLoading && (
					<Pagination {...this.state} onUpdate={this.onPageUpdate} />
				)}
			</div>
		);
	}
}
