import React, { Component } from 'react';
import Select from 'react-select';

import './InputDropdown.css';


export default class InputDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.value,
            options: this.createOptions()
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.itemMap !== this.props.itemMap) {
    //         if (this.props.id === 'campaign') {
    //             this.setState({isUpdating: true});
    //             let options = [this.state.options[0]];
    //             this.props.itemMap.forEach((item, itemId) => {
    //                 options.push(
    //                     <option key={itemId} value={itemId}>{item.prettyName}</option>
    //                 )
    //             });
    //             this.setState({
    //                 options: options,
    //             });
    //         }
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value !== this.props.value){
            // this.handleChange(this.props.value);
            this.setState({
                selected: this.props.value
            })
        }
        if(prevProps.itemMap !== this.props.itemMap){
            this.setState({
                options: this.createOptions()
            });
        }
    }

    handleChange = (selected) => {
        this.setState(
            { selected: selected},
            () => {
            this.props.onChange(this.props.id, this.state.selected);
        });

    }

    createOptions = () => {
        let options = [];
        this.props.itemMap.forEach( (item, itemId) => {
            options.push({value: itemId, label: item.prettyName})
        });

        return options;
    }

    render() {
        return (
            <Select
                value={this.state.selected}
                onChange={this.handleChange}
                options={this.state.options}
                placeholder={`Select ${this.props.name}`}
                isMulti={this.props.muiltSelect}
                isClearable={true}
                isFocused={true}
                closeMenuOnSelect={!this.props.muiltSelect}
            />
        )
    }
}
