import React from 'react';
// import NotFound from '../../../components/Views/NotFound/NotFound';
import NotAllowed from '../../../../Shared/Views/NotAllowed';


export default class RoleAwarePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			allowed: false
		}
	}

	componentDidMount() {
		const accessToken = localStorage.getItem('accessToken');

		if (this.props.role === "admin" && accessToken !== "undefined" && accessToken !== null) {
			this.setState({allowed: true});
		}
	}

	render() {
		if (this.state.allowed) {
			return this.props.children;
		} else {
			return <NotAllowed/>;
		}
	}
}
