import React, {Component} from "react";
import {Link} from "react-router-dom";
import {authContext} from "../../../adalConfig";

import './Header.css';


export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {userName: 'Loading...'};
	}

	logout = async () => {
		await localStorage.removeItem('accessToken');
		await localStorage.removeItem('user');
		authContext.logOut();
	}

	componentDidMount() {
		this.setState({'userName': this.props.user.name});
	}

	render() {
		return (
			<div className="header-inner">
				<div className="header-section">
					<a href="/"><h1 className="logo-mktg">MKTG</h1></a>
					<h2 className="logo-brand">Diageo</h2>
					<a href="/"><span className="header-text">Launchpad</span></a>
				</div>
				< div className="header-section">
					<span className="header-user-name">
						{this.state.userName}
					</span>
					<button className="logout-button" onClick={this.logout}>Logout</button>
					{this.props.user.role.includes('admin')?
						<Link to="/UserPermission" className="user-permission-button" /> : <div />
					}
				</div>
			</div>
		)
	}
}