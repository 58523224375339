import React, {Component} from 'react';
import './RadioInput.css';


export default class RadioInput extends Component {
	constructor(props){
		super(props);
		this.state = {
			currentOption: this.props.value,
			accessType: {
				'Admin': 'Admin',
				'Write': 'Editor',
				'Read': 'Observer'
			}
		}
	}

	componentDidMount() {
		this.setState({ currentOption: this.props.value })
	}

	componentDidUpdate(prevProps) {
		if(prevProps.value !== this.props.value){
			this.setState({ currentOption: this.props.value })
		}
	}

	handleChange = (target, accessOption) => {
		this.setState({ currentOption: accessOption })
		this.props.handleChange(target, accessOption)
	}

	renderRadioButtons = () => {
		let radioButtons = [];
		for( let accessOption in this.state.accessType) {
			radioButtons.push(
				<label className={`radio-wrapper radio-block ${this.props.selectedRole === 'admin' ? 'disabled' : ''}`} key={accessOption}>
					<input type="radio" value={accessOption} checked={accessOption === this.state.currentOption}
						   onChange={() => this.handleChange(this.props.target, accessOption)}
						   disabled={this.props.selectedRole === 'admin'}
					/>
					<span><span></span></span>
					{this.state.accessType[accessOption]}
				</label>
			)
		}
		return radioButtons;
	}

	render() {
		return (
			<div className={`radio-group-body ${this.props.selectedRole === 'admin' ? 'disabled' : ''}`}>
				<div> {this.props.prettyName} </div>
				{this.renderRadioButtons()}
			</div>
		)
	}
}
