import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {runWithAdal} from 'react-adal';
import {authContext} from './adalConfig';
import ReactGA from 'react-ga';
import App from './containers/App';

import './index.css';

authContext.log = function (level, message, error) {
	console.log(level, message, error);
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);


runWithAdal(authContext, () => {
	ReactDOM.render((
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	), document.getElementById('app'));
});
