import React from 'react';
import {authContext} from '../../../adalConfig';
import './Views.css';


export default class NotAllowed extends React.Component {
	logout = async () => {
		await localStorage.removeItem('accessToken');
		await localStorage.removeItem('user');
		authContext.logOut();
	}

	render() {
		return (
			<div className="custom-view">
				<h3>You don't have permission to view this page.</h3>
				<p>
					If this is a mistake, please try <a onClick={this.logout}>logging out</a> and back in.
					If the issue still persists, please contact your system administrator.
				</p>
			</div>
		)
	}
}