import React, {Component} from 'react';
import './SelectRole.css';


export default class SelectRole extends Component {
	handleChange = (e) => {
		this.props.onChange(this.props.target, e.target.value);
	}

	render() {
		return (
			<div>
				{this.props.label && this.props.showLabel &&
				<label className="label" htmlFor={this.props.id}>{this.props.label}</label>
				}
				<div className={this.props.disabled ? "custom-role-select disabled" : "custom-role-select"}>
					<select id={this.props.id} onChange={this.handleChange} value={this.props.value}
							disabled={this.props.disabled}>

						<option default value="">No Role Selected</option>

						{this.props.roles && this.props.roles.map(function (role, i) {
							return (
								<option value={role._id} key={i}>{role.prettyName}</option>
							)
						})}

					</select>
				</div>
			</div>
		)
	}
}
