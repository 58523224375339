import React, { Component } from 'react';
import moment from 'moment';
import {toast} from 'react-toastify';
// import update from "immutability-helper";

import Dropzone from './Dropzone/Dropzone';
// import Progress from './Progress/Progress';
import InputDropdown from '../InputDropdown/InputDropdown';
import SelectDate from '../SelectDate/SelectDate';

import './PhotoUpload.css';



export default class PhotoUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfulUploaded: false,
            brandId: undefined,
            brandName: undefined,
            channelId: undefined,
            channelName: undefined,
            marketId: undefined,
            marketName: undefined,
            campaignId: undefined,
            campaignName: undefined,
            eventDate: new Date(),
            description: " ",
            selectors: {
                brand: "",
                channel: "",
                market: "",
                campaign: "",
                eventName: undefined,
                eventId: undefined,
                category: undefined
            },

            filteredCampaign: this.props.campaigns
        };
    };

    componentDidMount() {
        this.filterCampaign();
    }

    //Add new files to the already added files
    onFilesAdded = (files) => {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }))
    }

    //Add progress bar
    // renderProgress = (file) => {
    //     const uploadProgress = this.state.uploadProgress[file.name];
    //     // console.log("Progress: " + uploadProgress );
    //     if(this.state.uploading || this.state.successfulUploaded) {
    //         return (
    //             <div className="ProgressWrapper">
    //                 <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
    //                 <img
    //                     className="CheckIcon"
    //                     alt="done"
    //                     src="/Check Icon.svg"
    //                     style={{
    //                         opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0}}
    //                 />
    //             </div>
    //         )
    //     }
    // }

    //PhotoUpload Files
    uploadFiles = async () => {
        if(this.handleValidate()){
            return;
        }
        this.setState({
            uploadProgress: {},
            uploading: true
        });
        const promises = [];
        this.state.files.forEach(file => {
            // promises.push(this.sendRequest(file));
            promises.push(this.sendFile(file));

        });
        try {
            await Promise.all(promises);
			toast.info("Uploading Photos. Please Wait", {autoClose: Math.sqrt(12 * promises.length + 10) * 1000});
            this.setState({
                successfulUploaded: true,
                uploading: false
            });
			setTimeout(() => {
				this.props.closeModal();
				this.props.reloadPhoto();
			}, Math.sqrt(12 * promises.length + 10) * 1000)

        }
        catch(err) {
            this.setState({
                successfulUploaded: true,
                uploading: false
            });
        }
    }

    // uploadFiles = () => {
    //     this.setState({
    //         uploadProgress: {},
    //         uploading: true
    //     });
    //     const promises = [];
    //     this.state.files.forEach(file => {
    //         const copy = { ...this.state.uploadProgress};
    //         copy[file.name] = { state: "done", percentage:100};
    //         this.setState({ uploadProgress: copy});
    //     });
    //     console.log("HELLO")
    //
    //     this.setState({
    //         successfulUploaded: true,
    //         uploading: false
    //     });
    //     // try {
    //     //     await Promise.all(promises);
    //     //     console.log("After Await")
    //     //     this.setState({
    //     //         successfulUploaded: true,
    //     //         uploading: false
    //     //     });
    //     // }
    //     // catch(err) {
    //     //     console.log("ERROR HUH")
    //     //     this.setState({
    //     //         successfulUploaded: true,
    //     //         uploading: false
    //     //     });
    //     // }
    // }

    // sendRequest = (file) => {
    //     return new Promise((resolve, reject) => {
    //         // const req = new XMLHttpRequest();
    //
    //         // req.upload.addEventListener("progress", event => {
    //         //     if(event.lengthComputable) {
    //         //         const copy = { ...this.state.uploadProgress };
    //         //         copy[file.name] = {
    //         //             state: "pending",
    //         //             percentage: (event.loaded / event.total) * 100
    //         //         };
    //         //         this.setState({ uploadProgress: copy});
    //         //     }
    //         // });
    //         //
    //         // req.upload.addEventListener("load", event=> {
    //         //     const copy = { ...this.state.uploadProgress};
    //         //     copy[file.name] = { state: "done", percentage: 100};
    //         //     this.setState({ uploadProgress: copy});
    //         //     resolve(req.response);
    //         // });
    //         //
    //         // req.upload.addEventListener("error", event => {
    //         //     const copy = { ...this.state.uploadProgress };
    //         //     copy[file.name] = { state: "error", percentage: 0};
    //         //     this.state({ uploadProgress: copy});
    //         //     reject(req.response);
    //         // });
    //
    //
    //         const copy = { ...this.state.uploadProgress};
    //         copy[file.name] = { state: "done", percentage:100};
    //         this.setState({ uploadProgress: copy});
    //
    //         console.log("Promise " + file.name + " : " + copy[file.name].state + " : " + copy);
    //         resolve();
    //
    //         // resolve(req.response);
    //
    //         // const formData = new FormData();
    //         // formData.append("file", file, file.name);
    //         // req.open("POST", "/Users/chun.wu/Documents/RJR-Launchpad/src/containers/Shared/PhotoUpload/PhotoUpload");
    //         // req.send(formData);
    //     })
    // }

    sendFile = (file) => {
        const accessToken = localStorage.getItem('accessToken');
        if(!accessToken) return;

        let url = process.env.REACT_APP_FILE_MANAGEMENT_API_URL + `file?name=${file.name}` +
            `&brandId=${this.state.brandId}&channelId=${this.state.channelId}&marketId=${this.state.marketId}` +
            `&campaignId=${this.state.campaignId}&description=${this.state.description}` +
            `&eventDate=${moment.utc(this.state.eventDate).format("YYYY-MM-DDTHH:mm:ss")}` +
            `&selectors=${JSON.stringify(this.state.selectors).replace(/['"{}]+/g, '')}`;
        url = encodeURI(url);

        let form = new FormData();
        form.append('file', file);

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Application': 'photoportal'
            },
            body: form
        }).then((response) => response.json()
        ).catch(err => {
            console.log("Error: " + err)
            toast("Error uploading photos", {
                className: "notification error"
            });
        });
    }

    handleDropdownSelect = (target, value) => {
        let id = null,
            name = null;
        if(value){
            id = value.value;
            name = value.label;
        }

        this.setState(prevState => ({
            [`${target}Id`]: id,
            [`${target}Name`]: name,
            selectors: {
                ...prevState.selectors,
                [target]: name
            }
        }), () => {
            if (target === 'brand' || target === 'channel') {
                this.filterCampaign()
            }
        });
	}

    filterCampaign = () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) return;

        fetch(process.env.REACT_APP_SERVER_API_URL + "filter?" +
            `brand=${this.state.brandId}&channel=${this.state.channelId}&campaign=${this.state.campaignId}` +
            `&eventDate=${moment.utc(this.state.eventDate).format("YYYY-MM-DDTHH:mm:ss")}`, {
            method: 'GET',
            headers: {
                'Authorization': accessToken
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then(responseJson => {
            let campaignMap = new Map();
            responseJson['data'].forEach((campaign) => {
                campaignMap.set(campaign._id, campaign);
            });

            //Don't change the campaign is the program is in the filtered list
            this.setState({
                campaignId: campaignMap.has(this.state.campaignId) ? this.state.campaignId : undefined,
                campaignName: campaignMap.has(this.state.campaignId) ? this.state.campaignName : undefined,
                filteredCampaign: campaignMap
            })
        }).catch(err => {
            console.log("Error: " + err)
            toast("Error loading Campaign Filter", {className: "notification error"});
        });
    }

    handleChange = (e) => {
        let target = e.target.id,
            value = e.target.value

        if (target === 'description') {
            if (value === "") {
                return this.setState({[target]: " "});
            }
            return this.setState({[target]: value});
        }

        this.setState(prevState => ({
            selectors: {
                ...prevState.selectors,
                [target]: value
            }
        }));
    }

    changeDate = (date) => {
        this.setState({eventDate: date}, this.filterCampaign);
    }

    handleValidate = () =>{
        let {brandId, channelId, marketId, campaignId, selectors} = this.state;
        let toastString = [];
        if (!brandId) {
            toastString.push("Please Select A Brand");
        }
        if (!channelId) {
            toastString.push("Please Select A Channel");
        }
        if (!marketId) {
            toastString.push("Please Select A Market");
        }
        if (!campaignId) {
            toastString.push("Please Select A Program");
        }
        if (toastString.length) {
            toast(<React.Fragment>{toastString.map( (str, i) =>
                <p key={i}>{str}</p>)}</React.Fragment>, {className: "notification error"});
            return true
        }
        return false
    }

    handleClearImage = () => {
        this.setState({
            files : []
        });
    }

    //Render Actions for upload files
    renderActions = () => {
        if(this.state.successfulUploaded) {
            return (
                <button onClick={() =>
                    this.setState({
                        files: [],
                        successfulUploaded: false
                    })}
                >
                    clear
                </button>
            );
        }
        else {
            return (
                <div className="button-wrapper">
                    <button className="cancel-button" onClick={this.props.closeModal}>Cancel</button>
                    <button className="clear-image-button" disabled={this.state.files.length<=0 || this.state.uploading}
                            onClick={this.handleClearImage}>Clear Photos</button>
                    <button disabled={this.state.files.length<=0 || this.state.uploading}
                            onClick={this.uploadFiles}>Upload</button>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="upload">
                <h1>Upload Files</h1>
                <div className="SelectRow">
                    <div className="SelectBlock">
                        <label htmlFor="brand">Brand</label>
                            <InputDropdown
                                id="brand" name="Brand"
                                itemMap={this.props.brands}
                                value={this.state.brandId ?
                                    {value: this.state.brandId, label: this.state.brandName} : null}
                                onChange={this.handleDropdownSelect}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="channel">Channel</label>
                            <InputDropdown
                                id="channel" name="Channel"
                                itemMap={this.props.channels}
                                value={this.state.channelId ?
                                    {value: this.state.channelId, label: this.state.channelName} : null}
                                onChange={this.handleDropdownSelect}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="market">Market</label>
                            <InputDropdown
                                id="market" name="Market"
                                itemMap={this.props.markets}
                                value={this.state.marketId ?
                                    {value: this.state.marketId, label: this.state.marketName} : null}
                                onChange={this.handleDropdownSelect}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="campaign">Program</label>
                            <InputDropdown
                                id="campaign" name="Program"
                                itemMap={this.state.filteredCampaign}
                                value={this.state.campaignId ?
                                    {value: this.state.campaignId, label: this.state.campaignName} : null}
                                onChange={this.handleDropdownSelect}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="eventDate">Event Date</label>
                            <SelectDate
                                id="eventDate"
                                value={this.state.eventDate}
                                onChange={this.changeDate}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="eventName">Special Event Name <p>- Optional</p></label>
                            <input
                                id="eventName" type='text'
                                className={"event-upload"}
                                placeholder="Input Event Name"
                                value={this.state.selectors.eventName}
                                onChange={this.handleChange}
                            />
                    </div>
                    <div className="SelectBlock">
                        <label htmlFor="eventName">Event ID <p>- Optional</p></label>
                            <input
                                id="eventId" type='text'
                                className={"event-upload"}
                                placeholder="Input Event ID"
                                value={this.state.selectors.eventId || ""}
                                onChange={this.handleChange}
                            />
                    </div>
                </div>
                <div className="SelectRow">
                    <div className="SelectBlock">
                        <label htmlFor="description">Description <p>- Optional</p></label>
                            <textarea
                                id="description"
                                value={this.state.description}
                                onChange={this.handleChange}
                            />
                    </div>
                </div>
                <div className="SelectRow">
                    <div className="SelectBlock">
                        <div className="Content">
                            <Dropzone
                                onFilesAdded={(files) => this.onFilesAdded(files)}
                                disabled={this.state.uploading || this.state.successfulUploaded}
                                filesCount={this.state.files.length}
                            />
                            {/*<div className="Files" >*/}
                            {/*    {this.state.files.map((file) => {*/}
                            {/*        return(*/}
                            {/*            <div key={file.name} className="Row">*/}
                            {/*                <span className="Filename">{file.name}</span>*/}
                            {/*                {this.renderProgress(file)}*/}
                            {/*            </div>*/}
                            {/*        );*/}
                            {/*    })}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="Actions" >{this.renderActions()}</div>
            </div>
        )
    }
}