import React, {Component} from "react";
import "./SearchBar.css";


export default class SearchBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: ""
		};
	}

	handleChange = (e) => {
		this.setState({value: e.target.value}, () => {
			if (this.state.value === "") {
				this.props.onSearch(this.state.value)
			}
		})
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.props.onSearch(this.state.value)
		}
	}

	handleSearchClick = (e) => {
		if (e.target === e.currentTarget) {
			this.props.onSearch(this.state.value)
		}
	}

	render() {
		return <div className="search-bar" style={this.props.style} onKeyPress={this.handleKeyPress} onClick={(e) => this.handleSearchClick(e)}>
			<input type="search" className="search-input" value={this.state.value}
				   onChange={(e) => this.handleChange(e)} placeholder={this.props.placeholder}/>
		</div>;
	}
}
