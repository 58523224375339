import React, { Component } from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import {toast} from "react-toastify";
import {FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';

import {getFilterParams} from '../../../utils';
import FilterDisplay from "./FilterDisplay/FilterDisplay";
import InputDropdown from '../InputDropdown/InputDropdown';
import SelectDate from '../SelectDate/SelectDate';
import PermissionAwarePage from "../../Shared/PermissionAwarePage/PermissionAwarePage";

import './FilterBar.css';


export default class FilterBar extends Component {
    constructor(props) {
        super(props);

        const filters = JSON.parse(sessionStorage.getItem('filters'));

        // Set default start date to start of fiscal year
        const fiscalStartTime = moment().month(6).startOf('month').year(2019).format('MM-DD-YYYY');

        if (filters) {
            this.state = {
                '_expanded': false,
				'filteredCampaign': this.props.campaigns,
                filters
            }
        } else {
            this.state = {
                '_expanded': false,
				'filteredCampaign': this.props.campaigns,
                filters: {
                    'brand': {
                        'value': [],
                        'name': 'Brand',
                        'id': 'brand'
                    },
                    'channel': {
                        'value': [],
                        'name': 'Channel',
                        'id': 'channel'
                    },
                    'market': {
                        'value': [],
                        'name': 'Market',
                        'id': 'market'
                    },
					'campaign': {
						'value': [],
						'name': 'Program',
						'id': 'campaign'
					},
					'startDate': {
						'value': new Date(fiscalStartTime),
						'name': 'Start Date',
						'id': 'startDate',
						'display': fiscalStartTime
					},
					'endDate': {
						'value': '',
						'name': 'End Date',
						'id': 'endDate',
						'display': ''
					},
					'verified': {
						'value': '',
						'name': 'Show Photos',
						'id': 'verified',
						'display': ''
					}
                }
            }
        }
    }

    componentDidMount() {
        // set initial filters and update parent
        sessionStorage.setItem('filters', JSON.stringify(this.state.filters));
		this.filterCampaign()
        // if (this.props.onUpdate) this.props.onUpdate();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state._expanded) {
            document.addEventListener('keydown', this.handleEscKey);
            document.body.classList.add("overlay", "lock-scroll");
        } else {
            document.removeEventListener('keydown', this.handleEscKey);
            document.body.classList.remove("overlay", "lock-scroll");
        }

        const prevFilters = JSON.stringify(prevState.filters);
        const newFilters = JSON.stringify(this.state.filters);

        if (newFilters !== prevFilters) {
            sessionStorage.setItem('filters', newFilters);
            this.props.onUpdate();
        }
    }

    toggleFlyout = () => {
        this.setState({
            _expanded: !this.state._expanded
        });
    }

    handleEscKey = (e) => {
        if (e.keyCode === 27) {
            this.toggleFlyout();
        }
    }

	handleDropdownSelect = (target, value) => {
		if(value === null){
			value = []
		}
		this.setState(prevState => ({
			filters: update(this.state.filters, {
				[target]: {
					value: {$set: value}
				}
			})
		}), () => {
			if (target === 'brand' || target === 'channel') {
				this.filterCampaign()
			}
		});
	}

	filterCampaign = () => {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		fetch(process.env.REACT_APP_SERVER_API_URL + "filter?" + getFilterParams(), {
			method: 'GET',
			headers: {
				'Authorization': accessToken
			},
		}).then(response => {
			if (response.ok) {
				return response.json();
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then(responseJson => {
			let campaignMap = new Map();
			responseJson['data'].forEach((campaign) => {
				campaignMap.set(campaign._id, campaign);
			});

			this.setState({
				filteredCampaign: campaignMap
			}, () => {
				//Don't change the campaign if the program(s) is in the filtered list
				let campaignToKeep = [];
				this.state.filters.campaign.value.forEach( (selectedCampaign) => {
					if (campaignMap.has(selectedCampaign.value)) {
						campaignToKeep.push(selectedCampaign);
					}
				});

				this.setState({
					filters: update(this.state.filters, {
						campaign: {
							value: {$set: campaignToKeep}

						}
					})
				})
			});
		}).catch(err => {
			console.log("Error: " + err);
			toast("Error loading Campaign Filter", {className: "notification error"});
		});
	}

    handleVerifyCheckboxChange = (type) => {
    	let value = this.state.filters.verified.value;
    	let display = '';

    	if(value === ''){
			value = type;
			display = type;
		}
		else if(value === type){
			value = '';
		}
		else if(value === 'both'){
			if(type ===  'verified'){
				value = 'unverified';
			}
			else {
				value = 'verified';
			}
			display = value;

		}
		else{
			value = 'both';
			display = 'verified, unverified';

		}

		this.setState({
			filters: update(this.state.filters, {
				["verified"]: {
					value: {$set: value},
					display: {$set: display}
				}
			})
		});
	}

	handleChangeDate = (date, dateId) => {
		const dateVal = date ? moment(date).format('MM-DD-YYYY') : '';

		this.setState({
			filters: update(this.state.filters, {
				[dateId]: {
					value: {$set: date},
					display: {$set: dateVal},

				}
			})
		}, () => {
			if(this.state.filters.startDate.value > this.state.filters.endDate.value) {
				this.setState({
					filters: update(this.state.filters, {
						endDate: {
							value: {$set: ''},
							display: {$set: ''},

						}
					})
				}, this.filterCampaign);
			}
			else {
				this.filterCampaign();
			}
		});
	}

    handleClear = (e) => {
        e.preventDefault();
		const fiscalStartTime = moment().month(6).startOf('month').year(2019).format('MM-DD-YYYY');

        this.setState({
            '_expanded': false,
			'filteredCampaign': this.props.campaigns,
            filters: {
				'brand': {
					'value': [],
					'name': 'Brand',
					'id': 'brand'
				},
				'channel': {
					'value': [],
					'name': 'Channel',
					'id': 'channel'
				},
				'market': {
					'value': [],
					'name': 'Market',
					'id': 'market'
				},
				'campaign': {
					'value': [],
					'name': 'Program',
					'id': 'campaign'
				},
				'startDate': {
					'value': new Date(fiscalStartTime),
					'name': 'Start Date',
					'id': 'startDate',
					'display': fiscalStartTime
				},
				'endDate': {
					'value': '',
					'name': 'End Date',
					'id': 'endDate',
					'display': ''
				},
				'verified': {
					'value': '',
					'name': 'Show Photos',
					'id': 'verified',
					'display': ''
				}
            }
        });
    }

    // handleClickOutside = (e) => {
    //     let isDatePickerEl = false;
    //     const targetClasses = JSON.stringify(e.target.classList);
	//
    //     isDatePickerEl = targetClasses.includes('react-datepicker');
	//
    //     if (this.state._expanded && !isDatePickerEl && !document.body.classList.contains('ReactModal__Body--open')) {
    //         this.setState({ _expanded: false });
    //     }
    // }

    render() {
        return (
            <div className={this.state._expanded ? 'filter-bar is-expanded' : 'filter-bar'}>
                <div className="top" onClick={this.toggleFlyout} id="top">
                    <FilterDisplay filters={this.state.filters}/>
                </div>

                <div className="flyout">
                    <div className="flyout-row">
                        <div className="filter">
                            <label htmlFor="brand">Brand</label>
								<InputDropdown
									id="brand" name="Brand" muiltSelect={true}
									itemMap={this.props.brands}
									value={this.state.filters.brand.value}
									onChange={this.handleDropdownSelect}
								/>
                        </div>
                        <div className="filter">
                            <label htmlFor="channel">Channel</label>
								<InputDropdown
									id="channel" name="Channel" muiltSelect={true}
									itemMap={this.props.channels}
									value={this.state.filters.channel.value}
									onChange={this.handleDropdownSelect}
								/>
                        </div>
                        <div className="filter">
                            <label htmlFor="market">Market</label>
								<InputDropdown
									id="market" name="Market" muiltSelect={true}
									itemMap={this.props.markets}
									value={this.state.filters.market.value}
									onChange={this.handleDropdownSelect}
								/>
                        </div>
						<div className="filter">
							<label htmlFor="campaign">Program</label>
								<InputDropdown
									id="campaign" name="Program" muiltSelect={true}
									itemMap={this.state.filteredCampaign}
									value={this.state.filters.campaign.value}
									onChange={this.handleDropdownSelect}
								/>
						</div>
                    </div>

                    <div className="flyout-row">
                        <div className="filter">
                            <label htmlFor="startDate">Start Date</label>
								<SelectDate
									id="startDate"
									value={this.state.filters.startDate ? this.state.filters.startDate.value : ''}
									onChange={this.handleChangeDate}
								/>
                        </div>
                        <div className="filter">
                            <label htmlFor="endDate">End Date</label>
								<SelectDate
									id="endDate"
									value={this.state.filters.endDate ? this.state.filters.endDate.value : ''}
									onChange={this.handleChangeDate}
									minDate={moment(this.state.filters.startDate.value).format("MM-DD-YYYY")}
								/>
                        </div>
						<PermissionAwarePage userPermission={this.props.toolAccess} allowedPermission={["Admin", "Write"]}>
							<div className="filter">
								<FormGroup row>
									<FormControlLabel
										control={<Checkbox checked={["verified", "both"].includes(this.state.filters.verified.value)}
												  onChange={() => this.handleVerifyCheckboxChange("verified")}
												  value={"Verified"} type="checkbox" color="primary" id={"verified"}/>}
										label="Verified"
									/>
									<FormControlLabel
										control={<Checkbox checked={["unverified", "both"].includes(this.state.filters.verified.value)}
											  onChange={() => this.handleVerifyCheckboxChange("unverified")}
											  value={"Unverified"} type="checkbox" color="primary" id={"unverified"}/>}
										label="Unverified"
									/>
								</FormGroup>
							</div>
						</PermissionAwarePage>
                    </div>

                    <div className="flyout-footer">
                        <a className="button" onClick={this.handleClear}>Clear Filters</a>
						<a className="close-button" onClick={this.toggleFlyout}>Close</a>
						<a className="apply-button" onClick={this.toggleFlyout}>Apply</a>
                    </div>
                </div>
            </div>
        )
    }
}