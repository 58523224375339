import React, {Component} from 'react';

import UserForm from '../../../components/drawers/UserForm/UserForm'
import Filters from '../../../components/elements/Filters/Filters'

import './Permission.css'


export default class Permission extends Component {
	render() {
		return <div>
			<ul className="sidebar-links">
				<li>
					<a onClick={() => this.props.expandDrawer(<UserForm roles={this.props.roles} narrow={false} handleRefresh={this.props.handleRefresh} handleCancel={this.props.handleCancel}/>)}>Add New User</a>
				</li>
			</ul>
			<Filters {...this.props}/>
		</div>;
	}
}
