import React, {Component} from 'react';
import {authContext} from "../../../adalConfig";
import {toast} from "react-toastify";
import {Tooltip} from "@material-ui/core";

import {VerifiedUser} from '@material-ui/icons';
import "./Verification.css";

export default class Verification extends Component {
	verifyPhoto = () => {
		let photoSet = this.props.selectedPhoto;
		let fileParams = "filesHash=";

		const accessToken = localStorage.getItem('accessToken');
		if(!accessToken) return;

		for(const photoHash of photoSet.keys()) {
			fileParams = fileParams + photoHash + ","
		}
		fileParams = fileParams.slice(0,-1);

		fetch(process.env.REACT_APP_SERVER_API_URL + `file/verification?${fileParams}`, {
			method: 'PUT',
			headers: {
				'Authorization': accessToken
			},
		}).then(response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.verifyPhoto();
				});
			} else if (response.ok) {
				return response.json();
			}
		}).then(responseJson => {
			this.props.clearPhoto();
		}).catch(err => {
			console.log("Error: " + err)
			toast("Error verifying photos", {
				className: "notification error"
			});
			this.setState({isLoading: false});
		});
	}

	unVerifyPhoto = () => {
		let photoSet = this.props.selectedPhoto;
		let fileParams = "filesHash=";

		const accessToken = localStorage.getItem('accessToken');
		if(!accessToken) return;

		for(const photoHash of photoSet.keys()) {
			fileParams = fileParams + photoHash + ","
		}
		fileParams = fileParams.slice(0,-1);

		fetch(process.env.REACT_APP_SERVER_API_URL + `file/verification/unverify=true?${fileParams}`, {
			method: 'PUT',
			headers: {
				'Authorization': accessToken
			},
		}).then(response => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.unVerifyPhoto();
				});
			} else if (response.ok) {
				return response.json();
			}
		}).then(responseJson => {
			this.props.clearPhoto();
		}).catch(err => {
			console.log("Error: " + err)
			toast("error un-verifying photos", {
				className: "notification error"
			});
			this.setState({isLoading: false});
		});
	}

	render() {
		return(
			<div className="verification">
				<Tooltip title="Verify Photo">
					<VerifiedUser htmlColor="#4492ec" onClick={this.verifyPhoto} disabled={this.props.selectedPhoto.size === 0}/>
				</Tooltip>
				<Tooltip title="Un-Verify Photo">
					<VerifiedUser htmlColor="#bdbdbd" onClick={this.unVerifyPhoto} disabled={this.props.selectedPhoto.size === 0}/>
				</Tooltip>
			</div>
		)
	}
}