import React, {Component} from "react";
import ReactGA from "react-ga";
import {Link} from 'react-router-dom'

import './ReportThumb.css';


export default class ReportThumb extends Component {
    static sendEvent(event_action) {
        ReactGA.event({
            category: 'clicks',
            action: event_action,
            label: 'dashboardView'
        });
    };

    getReportStyle = (report) => {
        let styleObj = report.name === this.props.currentReport.name ? {
            borderColor: "red",
            borderWidth: "medium thick"
        } : {}
        try {
            styleObj['backgroundImage'] = "url(" + require(`../../../media/Reports/${report.thumbnail}`) + ")";
            styleObj['color'] = "transparent";
            return styleObj
        } catch (e) {
            styleObj['background'] = 'black';
            return styleObj
        }
    }

    renderThumbs() {
        let thumbs = [];
        for (let key in this.props.reports) {
            let report = this.props.reports[key];
            if (this.props.page === report.productGroup) {
                thumbs.push(
                    <Link to={`/Report/${this.props.page}/${report.name}`} key={report.name}
                          onClick={() => ReportThumb.sendEvent(report.name)}>
                        <div className={'column'}
                             style={this.getReportStyle(report)}
                        >
                            <span><p>{report.prettyName}</p></span>
                        </div>
                    </Link>
                )
            }
        }
        return thumbs;
    }

    render() {
        return (
            <div className="row">
                {this.renderThumbs()}
            </div>
        )
    }
}