import React, {Component} from "react";
import {Link} from 'react-router-dom'
import './ReportLanding.css';


const reportTiles = ['diageo', 'dbc', 'experiential'];

export default class ReportMenu extends Component {
    render() {
        return (
            <div className='brand-row'>
                {reportTiles.map(r => (
                    <Link to={`/Report/${r}/`} key={r}>
                        <div className={`brand-column ${r}`}/>
                    </Link>
                ))}
            </div>
        )
    }
}