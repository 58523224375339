import React from 'react';
import {authContext} from '../../../adalConfig';
import './Views.css';


export default class DataCorrupted extends React.Component {
    logout = async () => {
        await localStorage.removeItem('accessToken');
        await localStorage.removeItem('user');
        authContext.logOut();
    }

    render() {
        return (
            <div className="custom-view">
                <h3>Your user data or access token might be corrupted.</h3>
                <p>
                    Please try <a onClick={this.logout}>logging out</a> and back in.
                    If the issue still persists, please contact your system administrator.
                </p>
            </div>
        )
    }
}