import React, { Component } from 'react';
import DetectClickOutside from 'react-click-outside';
import moment from 'moment';

import './PhotoEnlarger.css';


class PhotoEnlarger extends Component {
    detectClick = (e) => {
        if (e.target.tagName !== 'IMG') {
            this.props.onUpdate();
        }
    }

    render() {
        const data = this.props.data;

        return (
            (!data) ? <div /> :

            <div onClick={this.detectClick} className={this.props.source == null ? "enlarged-wrapper" : "enlarged-wrapper is-enlarged"}>
                <div className="enlarged-inner">

                    <img className="image" src={this.props.source} alt="" />

                    <div className="details">
                        <ul>
                            {/*<li><span>File Name:</span> {data.name ? data.name : 'n/a'}</li>*/}
                            <li><span>Brand:</span> {data.selectors.brand ? data.selectors.brand : 'n/a'}</li>
                            <li><span>Channel:</span> {data.selectors.channel ? data.selectors.channel : 'n/a'}</li>
                        </ul>
                        <ul>
                            <li><span>Market:</span> {data.selectors.market ? data.selectors.market : 'n/a'}</li>
                            <li><span>Program:</span> {data.selectors.campaign ? data.selectors.campaign : 'n/a'}</li>
                        </ul>
                        <ul>
                            <li><span>Event Date:</span> {data.eventDate ? moment(data.eventDate).format('MMM Do YYYY') : 'n/a'}</li>
                            <li><span>Upload Date:</span> {data.createdAt ? moment(data.createdAt).format('MMM Do YYYY') : 'n/a'}</li>
                        </ul>
                    </div>
                    <div className="details">
                        <ul><li><span>Description:</span> {data.description ? data.description : 'n/a'}</li></ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetectClickOutside(PhotoEnlarger);