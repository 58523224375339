import React, {Component} from 'react';
import './Loader.css';


export default class Loader extends Component {

	render() {
		return (
			this.props.singlePhotoLoader ?
				<div className="show-singlePhoto-loader-overlay"/> : <div className="show-loader-overlay"/>
			)
	}
}