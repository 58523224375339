import React, {Component} from "react";
import './BottomNav.css';


export default class Footer extends Component {
	render() {
		return (
			<div className="bottom-nav">
				<ul className="nav">
					<li><a
						href="https://dentsuaegis.okta-emea.com/home/concur-solutions/0oa1r9m3faOOXdRRy0i7/200?fromHome=true"
						target="_blank" rel="noopener noreferrer">
						Concur</a></li>
					<li>|</li>
					<li><a href="https://dentsuaegis.okta-emea.com/home/egnyte/0oa1vqoa28yY5XNkn0i7/36?fromHome=true"
						   target="_blank" rel="noopener noreferrer">Egnyte</a></li>
					<li>|</li>
					<li><a href="https://dentsuaegis.okta-emea.com/home/workday/0oa1ovuevvhoUHQIa0i7/30?fromHome=true"
						   target="_blank" rel="noopener noreferrer">
						Workday</a></li>
					<li>|</li>
					<li><a
						href="https://dentsuaegis.okta-emea.com/home/office365/0oa1ovblgiheRx61v0i7/30781?fromHome=true"
						target="_blank" rel="noopener noreferrer">
						Email</a></li>
					<li>|</li>
					<li><a href="https://admin.pexcard.com/admin.html"
						   target="_blank" rel="noopener noreferrer">PEX Card</a></li>
					<li>|</li>
					<li><a href="https://diageoacademy.csod.com/client/diageoacademy/external.aspx"
						   target="_blank" rel="noopener noreferrer">DWS</a></li>
					<li>|</li>
					<li><a href="https://account.docusign.com/"
						   target="_blank" rel="noopener noreferrer">Docusign</a></li>
					<li>|</li>
					<li><a href="https://support.mktg.com/"
						   target="_blank" rel="noopener noreferrer">IT Support</a></li>
				</ul>
			</div>)
	}
}