import React, { Component } from 'react'
import {CloudUploadRounded, InsertDriveFile} from "@material-ui/icons"

import './Dropzone.css'


export default class Dropzone extends Component {
    constructor(props) {
        super(props)
        this.fileInputRef = React.createRef()
        this.state = { highlight: false }
    }

    onDragOver = (evt) => {
        evt.preventDefault();
        if(this.props.disabled) return;
        this.setState({ highlight: true });
    }

    onDragLeave = () => {
        this.setState({ highlight: false });
    }

    onDrop = (evt) => {
        evt.preventDefault();
        if(this.props.disabled) return;
        const files = evt.dataTransfer.files;
        if(this.props.onFilesAdded) {
            const array = Dropzone.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.setState({ hightlight: false });
    }

    openFileDialog = () => {
        if(this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded = (evt) => {
        if(this.props.disabled) return;
        const files = evt.target.files;
        if(this.props.onFilesAdded) {
            const array = Dropzone.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    static fileListToArray = (list) => {
        const array = [];
        for(let i=0; i<list.length; i++){
            array.push(list.item(i));
        }
        return array;
    }

    render() {
        return (
            <div className={`dropzone ${this.state.highlight ? "hightlight": ""}`}
                onDragOver={(e) => this.onDragOver(e)} onDragLeave={() => this.onDragLeave()}
                onDrop={(e) => this.onDrop(e)} onClick={() => this.openFileDialog()}
                style={{cursor: this.props.disabled ? "default" : "pointer"}}>

                <input ref={this.fileInputRef} className="fileInput" type="file" multiple
                       onChange={(e) => this.onFilesAdded(e)}/>

                {this.props.filesCount > 0 ?
                    <div className="files-added-wrapper">
                        <InsertDriveFile style={{fontSize: 90}} htmlColor="#b3b3b3"/>
                        <span>{this.props.filesCount} files are in queue for Upload</span>
                    </div>
                    :
                    <div className="files-upload-wrapper">
                        <CloudUploadRounded style={{fontSize: 90}} htmlColor="#b3b3b3"/>
                        <span>Drag and Drop Here</span>
                        <span>or</span>
                        <span>Browse Files</span>
                    </div>
                }
            </div>
        );
    }
}