import React, {Component} from 'react';
import './Pagination.css';


export default class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1
		};
	}

	componentDidUpdate(nextProps, nextState) {
		//Check for the change in input
		if(this.state.currentPage !== nextState.currentPage) return;

		//Check for change with arrows
		if (nextProps.page !== this.state.currentPage) {
			this.setState({currentPage: this.props.page})
		}

	}

	componentDidMount() {
		this.setState({currentPage: this.props.page})
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter' && !isNaN(this.state.currentPage)) {
			this.props.onUpdate({page: this.state.currentPage})
		}
	};

	handleChange = (e) => {
		const pages = this.props.pages;
		const value = e.target.value;

		if (value === "") {
			this.setState({currentPage: ""});
		} else if (value > pages) {
			this.setState({currentPage: +pages});

		} else if (value < 1) {
			this.setState({currentPage: 1});
		} else {
			this.setState({currentPage: +value});
		}
	}

	render() {
		return (
			<div className="pagination" disabled={this.props.pages === 0}>
				<div
					className="pagination-info">Showing <span>{Math.min(this.props.limit, this.props.itemDisplayed)}</span> of <span>{this.props.total}</span> {this.props.label}
				</div>

				<div className="pagination-controls" disabled={this.props.pages === 1}>
					<span className="left-double-arrow" onClick={() => this.props.onUpdate({page: 1})}
						  disabled={this.props.page === 1}><div/></span>
					<span className="left-single-arrow" onClick={() => this.props.onUpdate({page: +this.props.page - 1})}
						  disabled={this.props.page === 1}><div/></span>
					<div className="page-control">
						<span>Page</span>
						<input value={this.state.currentPage} type="number" className="page-selector"
							   onChange={this.handleChange} onKeyPress={this.handleKeyPress}
							   disabled={this.props.pages === 1}/>
						<span>of <span>{this.props.pages}</span></span>
					</div>
					<span className="right-single-arrow" onClick={() => this.props.onUpdate({page: +this.props.page + 1})}
						  disabled={this.props.page === this.props.pages}><div/></span>
					<span className="right-double-arrow" onClick={() => this.props.onUpdate({page: this.props.pages})}
						  disabled={this.props.page === this.props.pages}><div/></span>
				</div>
			</div>
		)
	}
}