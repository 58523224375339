import React, {Component} from 'react';
import './Form.css';


export default class Form extends Component {
	render() {
		return (
			<form className={this.props.className}>
				{this.props.children}
			</form>
		)
	}

}
