import React from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';

import Form from '../../../components/elements/Form/Form';

import './CheckboxFilters.css';


export default class CheckboxFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			brands: [],
			channels: [],
			divisions: [],
			directors: [],
			markets: [],
			programs: [],
			roles: [],
			status: []
		};
	}

	componentDidMount() {
		let filters = localStorage.getItem("filters");
		if (filters) {
			filters = JSON.parse(filters);
			let {brands, channels, divisions, markets, programs, status, directors, roles} = filters;
			this.setState({brands, channels, divisions, markets, programs, status, directors, roles})
		}
	}

	componentDidUpdate(nextProps) {
		localStorage.setItem("filters", JSON.stringify(this.state));
		this.props.updateButton()
	}

	dataSelect = (value) => {
		this.setState({[this.props.target]: value}, () => {
			this.props.handleRefresh();
			this.props.handleTotalsRefresh();
			this.props.getFilters()
		})
	}

	clearSelection = (e) => {
		e.preventDefault();
		this.setState({[this.props.target]: []}, () => {
			this.props.handleRefresh();
			this.props.handleTotalsRefresh();
			this.props.getFilters()
		})
	}

	static compare(a, b) {
		if (a.prettyName < b.prettyName) return -1;
		if (a.prettyName > b.prettyName) return 1;
		return 0
	}

	checkForDuplicates = (acc, curr) => {
		let nameIdx = acc.names.indexOf(curr.prettyName);
		let data = curr;
		if (nameIdx < 0) {
			if (Array.isArray(data._id)) {
				data._id = data._id[0];
			}
			acc.names.push(data.prettyName);
			acc.data.push(data);
		} else {
			let targetName = 'joint-' + this.props.target;
			let ids = [];
			let newVals = {};
			let mainId = acc.data[nameIdx]._id;
			if (typeof this.state[targetName] !== 'undefined') {
				newVals = this.state[targetName];
				if (this.state[targetName].hasOwnProperty(mainId)) {
					ids = this.state[targetName][mainId];
					if (ids.includes(data._id)) return acc;
				}
			}
			ids.push(data._id);
			newVals[mainId] = ids;
			this.setState({[targetName]: newVals});
		}
		return acc;
	}

	render() {
		let data = this.props.data && Array.isArray(this.props.data) && this.props.alphabetize ? this.props.data.sort(CheckboxFilters.compare) : this.props.data;
		data = data.reduce(this.checkForDuplicates, {names: [], data: []}).data;
		return (
			<Form className="form">
				<div className="form-header">
					<div>Select {this.props.label}({this.props.labelPlural}) to Filter</div>
					<div className="buttons">
						<button className="clear" onClick={this.clearSelection}>Clear</button>
						<button className="apply" onClick={this.props.handleCancel}>Apply</button>
					</div>
				</div>
				<div className="form-body">
					<div className="checkbox-group-body">
						<Checkboxes onChange={this.dataSelect} checkboxDepth={2} value={this.state[this.props.target]}>
							{this.props.data && Array.isArray(this.props.data) && data.map(function (data, i) {
								return (
									<label htmlFor={data._id} className="checkbox-wrapper checkbox-column" key={i}>
										<Checkbox id={data._id} value={data._id}/>
										<span></span>
										{data.prettyName}
									</label>
								)
							})}
						</Checkboxes>
					</div>
				</div>
			</Form>
		)
	}
}
