import React from 'react';
import './Sidebar.css';


export default class Sidebar extends React.Component {
	render() {
		return (
			<section className="sidebar">
				<div className="sidebar-inner">
					{this.props.children}
				</div>
			</section>
		)
	}
}
