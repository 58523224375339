import React, {Component} from 'react';
import CryptoJS from 'crypto-js';

import Header from './Shared/Header/Header';
import Tiles from './Tiles/Tiles';
import Footer from './Shared/Footer/Footer';
import DataCorrupted from './Shared/Views/DataCorrupted'


import './Launchpad.css';


export default class DiageoClient extends Component {
	constructor(props) {
		super(props);
		
		try {
			let encryptedUser = localStorage.getItem('user');
			let decryptBytes = CryptoJS.AES.decrypt(encryptedUser.toString(), process.env.REACT_APP_SECERT_KEY);
			let user = (JSON.parse(decryptBytes.toString(CryptoJS.enc.Utf8)))
			this.state = {
				user: user,
				dataError: false
			}
		} catch(err) {
			console.log("err ====> ", err);
			this.state = {
				dataError: true
			}
		}
	}

	render() {
		return (
			this.state.dataError ? <DataCorrupted /> :

			<div className="app">
				<div className="header">
					<Header user={this.state.user} />
				</div>

				<Tiles user={this.state.user} />

				<Footer role={this.state.user.role} />

			</div>
		)
	}
}


