import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';

import 'flatpickr/dist/themes/light.css';
import './SelectDate.css';


export default class SelectDate extends Component {
	render() {
		return (
			<Flatpickr
				id={this.props.id}
				value={this.props.value}
				placeholder={"Select Date"}
				onChange={(date)=>this.props.onChange(new Date(date), this.props.id)}
				options={{
					dateFormat:"m-d-Y",
					minDate: this.props.minDate
				}}
			/>
		)
	}
}

