import React from 'react';
import './Drawer.css';


export default class Drawer extends React.Component {
	render() {
		return (
			<section className="drawer">
				{this.props.children}
			</section>
		)
	}
}
