import React, { Component } from 'react';
import Select from 'react-select';

import {Tooltip} from "@material-ui/core";
import {SortByAlpha} from "@material-ui/icons";
import './SortByDropdown.css';


export default class SortByDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: this.createOptions()
        }
    }

    createOptions = () => {
        return [
            {value: 'eventDate', label: 'Event Date'},
            {value: 'createdAt', label: 'Upload Date'},
            {value: 'selectors.brand', label: 'Brand'},
            // {value: 'selectors.channel', label: 'Channel'},
            {value: 'selectors.market', label: 'Market'},
            // {value: 'selectors.program', label: 'Program'}
            ];
    }

    render() {
        const customStyles = {
            control: (provided) => ({
                ...provided,
                borderStyle: 'none'
            }),
            indicatorSeparator: () => null,
            indicatorsContainer: () => ({
                borderStyle: 'none'
            }),
            singleValue: (provided) => ({
                ...provided,
                right: 0
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 5
            })
        }

        return (
            <div className='sortBy-dropdown-wrapper'>
                <div className='sortBy-dropdown'>
                    <Select
                        value={this.props.value}
                        onChange={(value) => this.props.onChange(value)}
                        options={this.state.options}
                        isFocused={true}
                        isSearchable={false}
                        styles={customStyles}
                    />
                </div>
                <Tooltip title='Reverse Sort Direction'>
                    <SortByAlpha htmlColor="#333333" onClick={this.props.changeSortOrder}/>
                </Tooltip>
            </div>
        )
    }
}
