import React, {Component} from "react";
import {Link} from 'react-router-dom';
import ReactGA from "react-ga";

import DataCorrupted from '../Shared/Views/DataCorrupted';

import './Tiles.css'


export default class Tiles extends Component {
	constructor(props){
		super(props);

		this.state = {
			tiles: [],
			dataError: false
		}
	}

	componentWillMount() {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return;

		fetch(process.env.REACT_APP_SERVER_API_URL + 'tiles/' +this.props.user.roleId,{
			method: 'GET',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((responseJson) => {
			this.setState({
				tiles: JSON.parse(responseJson['data'])
			});
		}).catch((err) => {
			console.log("err ====> ", err);
			this.setState({dataError: true});
		});
	}

	static sendEvent(event_action) {
		console.log(event_action);
		ReactGA.event({
			category: 'clicks',
			action: event_action,
			label: 'programAccess'
		});
	};

	static getTileStyle(tile){
		try {
			return {backgroundImage: "url(" + require(`../../media/${tile.tileImage}`) + ")"};
		}catch(e){
			return {background: 'black'};
		}
	}

	render() {
		const tiles =this.state.tiles.map( (tile) => {
			if(tile.active) {
				return (
					<div className="tile" key={tile.name} disabled={tile.maintenance}>
						{tile.link.includes("http") ?
							<a className="tile-body" id={tile.name} data-targetpage={tile.prettyName}
							   href={tile.link} target={tile.openSameTab ? "" : "_blank"}
							   disabled={tile.maintenance}
							   rel="noopener noreferrer" onClick={() => {
								Tiles.sendEvent(this.props.user.role + "-" + tile.name)
							}}
							>
								<h3>{tile.prettyName}</h3>
								<div className={"tile-image"} style={Tiles.getTileStyle(tile)}/>
							</a>

							:

							<Link className="tile-body" id={tile.name} data-targetpage={tile.prettyName}
								  to={{pathname: tile.link}}
								  disabled={tile.maintenance}
								  onClick={() => {
									  Tiles.sendEvent(this.props.user.role + "-" + tile.name)
								  }}
							>
								<h3>{tile.prettyName}</h3>
								<div className={"tile-image"} style={Tiles.getTileStyle(tile)}/>
							</Link>
						}

						<a className="tile-footer" href={tile.support} target="_blank">
							{tile.support.includes("http") ? <small>Support Link</small> : <small>Support Email</small>}
							<span>{tile.supportName}</span>
						</a>

					</div>
				)
			}})

		return (
			this.state.dataError ? <DataCorrupted/> :
			<div className="tilegrid">
				{tiles}
			</div>
		)
	}
}