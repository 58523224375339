import React, {Component} from "react";
import {authContext} from '../../../../../adalConfig';
import {toast} from 'react-toastify';

import SelectRole from "../../../components/elements/SelectRole/SelectRole";
import UserForm from "../../../components/drawers/UserForm/UserForm";
import RequestDelete from "../../../components/modals/RequestDelete/RequestDelete";

import "./UserItem.css";


export default class UserItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			roleId: this.props.selectedUser.roleId || "",
			roles: this.props.roles,
			isLoading: false,

			permission: this.props.selectedUser.permission || {
				planningtool: {
					access: "Read",
					prettyName: "Planning Tool",
					brands: [],
					channels: [],
					clients: [],
					directors: [],
					divisions: [],
					departments: [],
					markets: [],
					productGroups: [],
					selectAll: {
						brands: false,
						channels: false,
						clients: false,
						directors: false,
						divisions: true,
						departments: false,
						markets: false,
						productGroups: false,
					}
				},
				programstandard: {
					access: "Read",
					prettyName: "Program Standard"
				},
				photoportal: {
					access: "Read",
					prettyName: "Photo Portal"
				},
				accountselection: {
					access: "Read",
					prettyName: "Account Selection",
					markets: [],
					selectAll: {
						markets: false
					}
				}
			}
		};
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedUser !== this.props.selectedUser) {
			this.setState({
				roleId: this.props.selectedUser.roleId || ""
			});
		}
	}

	updateForAdmin = async (roleId) => {
		if (this.state.roles) {
			let userRole = this.state.roles.find(role => role._id === roleId);
			let selectedRole = userRole ? userRole.name : null;
			if (selectedRole === 'admin') {
				this.setState(prevState => ({
					permission: {
						...prevState.permission,
						planningtool: {
							...prevState.permission.planningtool,
							access: 'Admin',
							selectAll: {
								brands: true,
								channels: true,
								clients: true,
								directors: true,
								divisions: true,
								departments: true,
								markets: true,
								productGroups: true,
							}
						},
						programstandard: {
							...prevState.permission.programstandard,
							access: 'Admin',
						},
						photoportal: {
							...prevState.permission.photoportal,
							access: 'Admin'
						},
						accountselection: {
							...prevState.permission.accountselection,
							access: 'Admin',
							selectAll: {
								markets: true
							}
						}
					}
				}));
			}
		}
	}

	updateUser = (target, value) => {
		const accessToken =localStorage.getItem('accessToken');
		if (!accessToken) return;

		this.setState({isLoading: true});
		let newData = Object.assign({}, this.props.selectedUser, {roleId: value}, {permission: this.state.permission});

		fetch(process.env.REACT_APP_SERVER_API_URL + 'user/' + this.props.selectedUser._id, {
			method: 'PUT',
			headers: {
				'Authorization': accessToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(newData)
		}).then((response) => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.updateUser(target, value)
				})
			} else if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((json) => {
			if (!json) return;
			toast("User Updated Successfully", {className: "notification success"});
			this.setState({roleId: value});
			this.setState({isLoading: false})

			this.props.getUsers() //Fetch the entire user list to make sure users are up to date with the database
		}).catch((error) => {
			toast(error.message, {className: "notification error"});
			this.setState({isLoading: false})
		});
	}

	handleChange = async (target, value) => {
		await this.updateForAdmin(value);
		this.updateUser(target, value);
	}

	handleDelete = () => {
		const accessToken =localStorage.getItem('accessToken');
		if (!accessToken) return;

		this.setState({isLoading: true});

		fetch(process.env.REACT_APP_SERVER_API_URL + 'user/' + this.props.selectedUser._id, {
			method: 'DELETE',
			headers: {
				'Authorization': accessToken
			}
		}).then((response) => {
			if (response.status === 498) {
				authContext._renewIdToken((err, token) => {
					this.handleDelete(this.props.selectedUser._id)
				})
			} else if (response.ok) {
				return response.json()
			} else {
				return response.json().then(Promise.reject.bind(Promise));
			}
		}).then((json) => {
			if (!json) return;
			toast("User Successfully Deleted", {className: "notification success"});
			this.setState({isLoading: false});
			this.props.handleRefresh();
			this.props.closeModal()
		}).catch((error) => {
			toast(error.message, {className: "notification error"});
			this.setState({isLoading: false})
		});
	}

	render() {
		let user = this.props.selectedUser;

		return (
			<div className="user">
				<span className="user-name">
				   {user.lastName}, {user.firstName}
				</span>
				<div className="user-role">
					<SelectRole
						label="Role"
						id="role"
						disabled={this.state.isLoading}
						value={this.state.roleId || ""}
						target="roleId"
						showLabel={false}
						roles={this.props.roles}
						onChange={this.handleChange}
					/>
				</div>
				<button className="user-edit"
						onClick={() => this.props.expandDrawer(
							<UserForm roles={this.props.roles} user={user} narrow={false} handleRefresh={this.props.handleRefresh}
									  handleCancel={this.props.collapseDrawer} />)} />
				<button className="user-delete"
						onClick={() => this.props.openModal(<RequestDelete handleCancel={this.props.closeModal}
																		   handleDelete={this.handleDelete}/>)} />
			</div>
		);
	}
}
